<!--   layout="d3ForceDirected"
  layout="colaForceDirected"
-->
<!--   [links]="data.links"-->
<form (ngSubmit)="loadSKOS()"
      [formGroup]="form">
  <mat-form-field>
    <mat-label>SKOS Vocab-URL</mat-label>
    <input matInput type="text" formControlName="url">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Filter Target Vocab Id</mat-label>
    <input matInput type="text" placeholder="e.g. 'learningResourceType'" formControlName="vocabId">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Show links of type</mat-label>
    <mat-select formControlName="link">
      <mat-option value="">All Types</mat-option>
      <mat-option *ngFor="let link of SUPPORTED_LINKS" [value]="link.id">{{link.label}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox formControlName="hideEmpty">Hide Non-Linked Items</mat-checkbox>
  <button mat-flat-button color="primary" type="submit">Load</button>
</form>
<ngx-graph
  *ngIf="data"
  class="chart-container"
  layout="dagreCluster"
  [clusters]="data.clusters"
  [view]=[window.innerWidth,window.innerHeight]
  [nodes]="data.nodes"
  [links]="data.links"
  [draggingEnabled]="false"
  [autoZoom]="true"
  [showMiniMap]="true"
>

  <ng-template #nodeTemplate let-node>
    <svg class="node" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g>
        <a [attr.href]="node.id" target="_blank">
          <rect [attr.width]="node.dimension.width" [attr.height]="node.dimension.height + 2" [attr.fill]="node.data.color" />
          <text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2 - 7">{{node.label}}</text>
          <text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2 + 6" [attr.font-size]="7">{{node.path}}</text>
          <text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2 + 14" [attr.font-size]="4">{{node.id}}</text>
        </a>
      </g>
    </svg>
  </ng-template>

  <ng-template #linkTemplate let-link>
    <svg:g class="edge">
      <svg:path class="line" stroke-width="2" marker-end="url(#arrow)">
      </svg:path>
      <svg:text class="edge-label" text-anchor="middle">
        <textPath class="text-path" [attr.href]="'#' + link.id" [style.dominant-baseline]="link.dominantBaseline" startOffset="50%">
          {{link.label}}
        </textPath>
      </svg:text>
    </svg:g>
  </ng-template>
</ngx-graph>
